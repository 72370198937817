import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { isMobile } from 'react-device-detect'
import ThemeProvider, { GlobalStyle } from './Theme'
import LocalStorageContextProvider, { Updater as LocalStorageContextUpdater } from './contexts/LocalStorage'
import TokenDataContextProvider, { Updater as TokenDataContextUpdater } from './contexts/TokenData'
import GlobalDataContextProvider from './contexts/GlobalData'
import PairDataContextProvider, { Updater as PairDataContextUpdater } from './contexts/PairData'
import { CHAINS_LIST_URL } from './constants'
import ApplicationContextProvider from './contexts/Application'
import UserContextProvider from './contexts/User'
import App from './App'

import { BrowserRouter } from 'react-router-dom'

const TokenDataContextUpdaterMEMO = React.memo(TokenDataContextUpdater)
const PairDataContextUpdaterMEMO = React.memo(PairDataContextUpdater)

// initialize GA
const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      storage: 'none',
      storeGac: false,
    },
  })
  ReactGA.set({
    anonymizeIp: true,
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

async function fetchChains() {
  try {
    const response = await fetch(CHAINS_LIST_URL)
    const data = await response.json()
    const enabledChains = data.filter((chain) => chain.frontend.featureFlags.app[process.env.REACT_APP_ENV_TYPE])
    return enabledChains
  } catch (error) {
    console.error('Failed to fetch chains:', error)
    return null
  }
}

function ContextProviders({ children, chains }) {
  return (
    <LocalStorageContextProvider>
      <ApplicationContextProvider chains={chains}>
        <TokenDataContextProvider>
          <GlobalDataContextProvider>
            <PairDataContextProvider>
              <UserContextProvider>{children}</UserContextProvider>
            </PairDataContextProvider>
          </GlobalDataContextProvider>
        </TokenDataContextProvider>
      </ApplicationContextProvider>
    </LocalStorageContextProvider>
  )
}

function Updaters() {
  return (
    <>
      <LocalStorageContextUpdater />
      <PairDataContextUpdaterMEMO />
      <TokenDataContextUpdaterMEMO />
    </>
  )
}

async function init() {
  const chains = await fetchChains()
  ReactDOM.render(
    <BrowserRouter>
      <ContextProviders chains={chains}>
        <Updaters />
        <ThemeProvider>
          <>
            <GlobalStyle />
            <App />
          </>
        </ThemeProvider>
      </ContextProviders>
    </BrowserRouter>,
    document.getElementById('root')
  )
}

init()
