import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const getClient = (uri) =>
  new ApolloClient({
    link: new HttpLink({ uri }),
    cache: new InMemoryCache(),
    shouldBatch: true,
  })

export const healthClient = getClient('https://api.thegraph.com/index-node/graphql')
